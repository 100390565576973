<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- left -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="$router.push({ name: 'integrations.create' })"
              >
                <span class="text-nowrap">Создать</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCompanyListTable"
        class="position-relative list-view-table"
        :items="items"
        responsive
        :fields="[
          { key: 'name', label: 'Интеграция', sortable: true },
          { key: 'is_active', label: 'Состояние' },
          { key: 'actions', label: 'Действия' },
        ]"
        primary-key="key"
        :sort-by="'id'"
        show-empty
        empty-text="Ничего не найдено!"
        :sort-desc="true"
        no-local-sorting
      >
        <template #cell(name)="data">
          <b-link :to="{ name: 'integrations.show', params: { id: data.item.id, type: data.item.type } }">
            {{ data.item.name }}
          </b-link>
          <div class="small text-monospace">
            {{ data.item.humanType }}
          </div>
        </template>

        <template #cell(is_active)="data">
          <div
            v-if="!data.item.is_active && !data.item.error"
            class=""
          >
            <b-alert
              variant="warning"
              show
              class="mb-0"
            >
              <div class="alert-body">
                <span>Ожидает подтверждения прав</span>
              </div>
            </b-alert>
          </div>
          <div
            v-else-if="!data.item.is_active && data.item.error"
            class=""
          >
            <b-alert
              variant="danger"
              show
              class="mb-0"
            >
              <h4 class="alert-heading">
                {{ data.item.error }}
              </h4>
              <div
                v-if="data.item.error_description"
                class="alert-body"
              >
                <span>{{ data.item.error_description }}</span>
              </div>
            </b-alert>
          </div>
          <div
            v-else-if="data.item.is_active"
            class=""
          >
            <b-badge
              variant="success"
              pill
            >
              Работает
            </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'integrations.show', params: { id: data.item.id, type: data.item.type } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Посмотреть</span>
            </b-dropdown-item>

            <template
              v-if="$store.getters['workingMode/checkPermission']('manage_integrations')"
            >
              <b-dropdown-item :to="{ name: 'integrations.edit', params: { id: data.item.id, type: data.item.type } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Изменить</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteItem(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </template>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BOverlay, BLink, BBadge, BAlert,
} from 'bootstrap-vue'

import {
  getCurrentInstance, onMounted, ref, computed,
} from 'vue'

import get from 'lodash/get'
import { getYandexMarketFbsIntegrations, deleteYandexMarketFbsIntegration } from '@/services/main-api/integrations/yandex-market-fbs'
import { getYandexMarketDbsIntegrations, deleteYandexMarketDbsIntegration } from '@/services/main-api/integrations/yandex-market-dbs'
import { getMerlionFreshYmfbsIntegrations, deleteMerlionFreshYmfbsIntegration } from '@/services/main-api/integrations/merlion-fresh-ymfbs'
import { getMerlionYmdbsIntegrations, deleteMerlionYmdbsIntegration } from '@/services/main-api/integrations/merlion-ymdbs'
import { types } from '@/composables/useIntegrations'
import useDeleteItem from '@/composables/useDeleieItem'

export default {
  name: 'ListView',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BLink,
    BBadge,
    BAlert,
  },
  setup() {
    const instance = getCurrentInstance().proxy
    const loading = ref(false)

    const ymfbsItems = ref([])

    async function getYandexMarketFbsIntegrationItems() {
      try {
        const res = await getYandexMarketFbsIntegrations({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
        })
        ymfbsItems.value = res.data.data.map(i => ({
          ...i,
          key: `yandex_market_fbs_${i.id}`,
          type: 'yandex_market_fbs',
          humanType: 'ЯндексМаркет FBS',
        }))
      } catch (e) {
        ymfbsItems.value = []
      }
    }

    const ydbsItems = ref([])

    async function getYandexMarketDbsIntegrationItems() {
      try {
        const res = await getYandexMarketDbsIntegrations({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
        })
        ydbsItems.value = res.data.data.map(i => ({
          ...i,
          key: `yandex_market_dbs_${i.id}`,
          type: 'yandex_market_dbs',
          humanType: 'ЯндексМаркет DBS',
        }))
      } catch (e) {
        ydbsItems.value = []
      }
    }

    const mFYItems = ref([])

    async function getMerlionFreshYmfbsIntegrationItems() {
      try {
        const res = await getMerlionFreshYmfbsIntegrations({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
        })
        mFYItems.value = res.data.data.map(i => ({
          ...i,
          key: `merlion-fresh-ymfbs_${i.id}`,
          type: 'merlion-fresh-ymfbs',
          humanType: 'Merlion ЯндексFBS FreshLogic',
        }))
      } catch (e) {
        mFYItems.value = []
      }
    }

    const mYdbsItems = ref([])

    async function getMerlionYmdbsIntegrationItems() {
      try {
        const res = await getMerlionYmdbsIntegrations({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
        })
        mYdbsItems.value = res.data.data.map(i => ({
          ...i,
          key: `merlion-ymdbs_${i.id}`,
          type: 'merlion-ymdbs',
          humanType: 'Merlion ЯндексDBS',
        }))
      } catch (e) {
        mYdbsItems.value = []
      }
    }

    const items = computed(() => [
      ...ymfbsItems.value,
      ...ydbsItems.value,
      ...mFYItems.value,
      ...mYdbsItems.value,
    ])

    const getData = async () => {
      loading.value = true
      await Promise.all([
        getYandexMarketFbsIntegrationItems(),
        getYandexMarketDbsIntegrationItems(),
        getMerlionFreshYmfbsIntegrationItems(),
        getMerlionYmdbsIntegrationItems(),
      ])
      loading.value = false
    }

    const { deleteItem } = useDeleteItem({
      deleteHandler: async item => {
        if (item.type === 'yandex_market_fbs') {
          await deleteYandexMarketFbsIntegration(item.id)
        } else if (item.type === 'yandex_market_dbs') {
          await deleteYandexMarketDbsIntegration(item.id)
        } else if (item.type === 'merlion-fresh-ymfbs') {
          await deleteMerlionFreshYmfbsIntegration(item.id)
        } else if (item.type === 'merlion-ymdbs') {
          await deleteMerlionYmdbsIntegration(item.id)
        }
      },
      getQuestionTextBeforeDeletion: item => `Интеграция ${item.name} будет удалена`,
      getTextAfterDeletion: () => 'Интеграция был удалена.',
      runAfterDeletion: async () => {
        await getData()
      },
    })

    onMounted(() => {
      getData()
    })

    return {
      loading,
      items,

      deleteItem,

      types,

      get,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
